import { Subscription } from 'rxjs';
import { Title, Meta, DomSanitizer } from '@angular/platform-browser';
import { UserService } from './../../user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from './../../services/helper.service';
import { Component, OnInit, Input, Inject, PLATFORM_ID, OnDestroy, OnChanges, SimpleChanges, SecurityContext, Output, EventEmitter } from '@angular/core';
import { NewsArticle } from '../news-article';
import { NewsArticleService } from '../news-article.service';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser, Location } from '@angular/common';
import baguetteBox from 'baguettebox.js';
import { Topic } from '../../comment/topic';
import { parse } from 'node-html-parser';

declare var $: any;
declare let ga: Function;

@Component({
  selector: 'dg-news-article-single-page',
  templateUrl: './news-article-single-page.component.html',
  styles: []
})
export class NewsArticleSinglePageComponent implements OnInit, OnChanges, OnDestroy {

  @Input() article: NewsArticle = new NewsArticle();
  @Output() articleUpdated: EventEmitter<NewsArticle> = new EventEmitter<NewsArticle>();
  isArticleAlreadyLoaded: boolean = false;
  previousArticle: NewsArticle = new NewsArticle();
  nextArticle: NewsArticle = new NewsArticle();
  topic: Topic = new Topic();

  // User login status
  loggedInSub: Subscription;

  // Like Button
  isLikeButtonLoading: boolean = false;

  // JSON+LD schema
  schema: object = {};

  constructor(
    public helperService: HelperService,
    private route: ActivatedRoute,
    private router: Router,
    private newsArticleService: NewsArticleService,
    public userService: UserService,
    private titleService: Title,
    private metaService: Meta,
    private sanitizer: DomSanitizer,
    public location: Location,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.route.params.subscribe( params => {
      if (this.route.snapshot.paramMap.get('path')) {
        this.article = new NewsArticle();
        this.article.slug = this.route.snapshot.paramMap.get('path').replace('.html','');
        this.isArticleAlreadyLoaded = false;
        this.newsArticleService.isNewsArticleViewFull = true;

        if (this.article.slug) {
          this.initArticle();
        }

        if (isPlatformBrowser(this.platformId) && this.newsArticleService.isNewsArticleViewFull) {
          // simple styling to prevent footer from rendering at the top of the page on slower connections where the article content loads slower
          document.body.classList.add('single-article');
        }
      }
    });
  }

  ngOnInit() {
    // if (this.article.slug) {
    //   this.initArticle();
    // }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['article'].currentValue.slug) {
      if (this.article != changes['article'].currentValue) {
        this.article = changes['article'].currentValue;
      }
      if (!this.article.isLoaded) {
        this.isArticleAlreadyLoaded = false;
      }
      //this.article = changes['article'].currentValue;
      this.initArticle();
    }
  }

  ngOnDestroy() {
    this.article = new NewsArticle();
    this.previousArticle = new NewsArticle();
    this.nextArticle = new NewsArticle();
    this.newsArticleService.isNewsArticleViewFull = false;
    if (isPlatformBrowser(this.platformId)) {
      // Remove class from body
      document.body.classList.remove('news-article-open');

      if (this.newsArticleService.isNewsArticleViewFull) {
        // remove single article class from body
        document.body.classList.remove('single-article');
      }
    }
  }

  initArticle() {
    if (!this.isArticleAlreadyLoaded) {
      this.isArticleAlreadyLoaded = true;
      this.newsArticleService.getArticle(this.article.slug).subscribe(articleData => {
        this.article = this.newsArticleService.mutateArticle(articleData);
        this.article.isLoaded = true;

        // Get category titles
        let categoryNames = this.article.categories.map((cat) => cat.name);

        let imageArray = [];

        // Define schema
        this.schema = {
          "@context" : "http://schema.org",
          "@type" : "Article",
          "name" : this.article.title,
          "author" : {
            "@type" : "Person",
            "name" : this.article.author.name,
          },
          "headline" : this.article.title,
          "articleSection": categoryNames,
          "articleBody": this.article.content.rendered,
          "datePublished" : this.article.date,
          "image" : this.article.featuredImage.full.src,
          "url" : environment.url + '/news/article/' + this.article.cleanLink,
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": environment.url + '/news/article/' + this.article.cleanLink,
            "breadcrumb": {
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": "News",
                  "item": environment.url + "/news"
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": this.article.title,
                  "item": environment.url + this.router.url
                },
              ]
            }
          },
          "publisher" : {
            "@type": "Organization",
            "name" : "DodgeGarage",
            "logo" : {
              "@type": "ImageObject",
              "contentUrl": "https://www.dodgegarage.com/assets/images/logos/dodge-garage-logo.png",
              "url": "https://www.dodgegarage.com/assets/images/logos/dodge-garage-logo.png",
              "width": 662,
              "height": 157
            },
            "url" : "https://www.dodgegarage.com"
          },
        };

        if(categoryNames.includes('Gallery')) {
          // HTML Parse
          const articleRoot = parse(this.article.content.rendered);

          imageArray = articleRoot.querySelectorAll('.wp-block-gallery li figure');

          if(imageArray.length) {
            // Add to schema
            this.schema['hasPart'] = imageArray.map((el) => {
              let figcaption = el.querySelector('figcaption');
              let name = figcaption ? figcaption.innerHTML : el.querySelector('img').getAttribute('alt');
              let url = el.querySelector('a').getAttribute('href');

              return {
                "@type": "ImageObject",
                "name" : name,
                "url": url
              };
            });
          }
        }
        
        //setTimeout(() => {
          // Set Title
          this.titleService.setTitle( this.helperService.htmlDecode(this.article.title) + ' | Dodge Garage' );
          // Set Meta Data
          //remove <p> from content value in object
          let strippedContent = this.helperService.htmlDecode(this.sanitizer.sanitize(SecurityContext.HTML, this.article.excerpt)).substr(0, 160);
          this.metaService.updateTag({ name: 'description', content: strippedContent });
          this.metaService.updateTag({ rel: 'canonical', href: environment.url + '/news/article/' + this.article.cleanLink });
          // Check is url matches the canonical link
          
          //if (this.router.url != '/news/article/' + this.article.cleanLink)
            this.metaService.updateTag({ property: 'robots', content: 'noindex, follow' });
            this.metaService.updateTag({ property: 'og:description', content: strippedContent });
            this.metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' });
            this.metaService.updateTag({ property: 'og:title', content: this.helperService.htmlDecode(this.article.title) });
            this.metaService.updateTag({ property: 'og:type', content: 'Article' });
            this.metaService.updateTag({ property: 'og:image', content: this.article.socialImage ? this.article.socialImage : this.article.featuredImage.src });
            this.metaService.updateTag({ property: 'og:url', content: environment.url + '/news/article/' + this.article.cleanLink });
            this.metaService.updateTag({ name: 'twitter:card', content: 'summary' });
            this.metaService.updateTag({ name: 'twitter:site', content: 'Dodge Garage' });
            this.metaService.updateTag({ name: 'twitter:title', content: this.helperService.htmlDecode(this.article.title) });
            this.metaService.updateTag({ name: 'twitter:description', content: strippedContent });
            this.metaService.updateTag({ name: 'twitter:image', content: this.article.featuredImage.src });

        //}, 1000);

        this.fetchNewsArticleData(this.article);

        if (isPlatformBrowser(this.platformId)) {

          // Add class to body
          document.body.classList.add('news-article-open');

          // Get Related Content
          this.fetchRelatedContent();

          // Date for GA reporting
          let pubDate = new Date(this.article['date']);
          let pubDateFormatted = `${pubDate.getMonth() + 1}/${pubDate.getDate()}/${pubDate.getFullYear()}`;

          // Event sent on page load to capture estimated read time of article
          ga('send', 'event', 'News ERT', this.location.path(), this.article['readTime']);
          ga('send', 'event', 'Post Date', this.location.path(), pubDateFormatted);

          // GA Data for categories and tags
          this.article.categories.forEach( category => {
            setTimeout(() => {
              ga('send', 'event', 'Custom Dimension Event', 'Article Categories', 'Article Categories', {nonInteraction: true, 'dimension1': category.name, 'metric1': 1});
            }, 3000);
          });
          this.article.tags.forEach( tag => {
            setTimeout(() => {
              ga('send', 'event', 'Custom Dimension Event', 'Article Tags', 'Article Tags', {nonInteraction: true, 'dimension7': tag.name, 'metric2': 1});
            }, 3000);
          });

          // Listen for user login change
          this.loggedInSub = this.userService.loggedIn$.subscribe(
          loggedIn => {
            if (this.userService.userModel.isLoggedIn) {
              // Check if user has liked this article (userModel)
              this.isArticleLikedByUser();

            }
          });

          // Setup Gallery
          setTimeout(function() {
            // Fixes to get full sized images
            let galleryLinks = document.querySelectorAll(".wp-block-gallery a");
            let i=0;
            for (i; i<galleryLinks.length; i++) {
              // Fix for Gutenberg gallery
              // Find this pattern with regex: "-digits(n)xdigits(n)." then replace with a single dot
              let imageLink = (<HTMLAnchorElement>galleryLinks[i]).href;
              let regex = /-\d*x\d*\./;
              
              // Replace the regex pattern and also replace "http://" with "https://"
              imageLink = imageLink.replace(regex, '.').replace('http://', 'https://');
              
              // Update the href attribute with the modified link
              galleryLinks[i].setAttribute('href', imageLink);
            }
            baguetteBox.run('.gallery, .wp-block-gallery', {
              captions: function(element) {
                if (element.parentElement.parentElement.querySelector('figcaption'))
                  return element.parentElement.parentElement.querySelector('figcaption').textContent;
              }
            });
          }, 2000);
        }
        this.articleUpdated.emit(this.article);
      });
    }
  }

  fetchRelatedContent() {
    let category = this.article.categories[0];
    if (category) {
      this.newsArticleService.getRelatedArticles(category).subscribe( data => {
        let articleIndex = data.findIndex( article => article.id === this.article.id );

        if (data[articleIndex-1]) {
          this.previousArticle = this.newsArticleService.mutateArticle(data[articleIndex-1]);
        }
        if (data[articleIndex+1]) {
          this.nextArticle = this.newsArticleService.mutateArticle(data[articleIndex+1]);
        }
      });
    }
  }

  fetchNewsArticleData(article: NewsArticle) {
    this.newsArticleService.getArticleData(article.id).subscribe( data => {
      // Update Article Data
      this.article.restId = data.id
      this.article.discourseTopicId = data.discourseTopicId;
      this.article.discourseCategoryId = data.discourseCat
      this.article.likes = (data.newsArticleLikes)?data.newsArticleLikes.length:0;
      this.newsArticleService.newsArticleOpen.likes = this.article.likes;
      if (isPlatformBrowser(this.platformId)) {
        // Check if user has liked this article (local storage)
        this.isArticleLikedByUser();
      }
      // This is where we set up variables for comments
      this.topic = new Topic();
      this.topic.discourseTopicId = data.discourseTopicId;
      this.topic.discourseCategoryId = data.discourseCategoryId;
      this.topic.polymorphicId = data.id;
      this.topic.polymorphicType = 'News Article';
      this.topic.title = this.article.title;
      this.topic.url = environment.url + '/news/article/' + this.article.cleanLink;
    });
  }

  topicCreated(event) {
    this.topic.discourseTopicId = event.discourseTopicId;
    this.topic.discourseCategoryId = event.discourseCategoryId;
    this.newsArticleService.updateArticleWithTopic(this.article.restId, this.topic.discourseTopicId, this.topic.discourseCategoryId).subscribe( data => {
    });
  }

  isArticleLikedByUser() {
    // Check local storage if the article has been liked by user
    if (localStorage.getItem('newsArticleLikes') != null) {
      let localStorageLikes: Array<any> = JSON.parse(localStorage.getItem('newsArticleLikes'));
      let isArticleLikedByUser = localStorageLikes.find(newsArticleLikes => newsArticleLikes.newsArticleRestId == this.article.restId);
      if (isArticleLikedByUser)
        this.article.isLikedByUser = true;
    }
    // Check the user model if the article as been liked by user
    if (this.userService.userModel.isLoggedIn) {
      let isArticleLikedByUser = this.userService.userModel.newsArticleLikes.find(newsArticleLikes => newsArticleLikes.newsArticleRestId == this.article.restId)
      if (isArticleLikedByUser)
        this.article.isLikedByUser = true;
        //this.newsArticleService.newsArticleOpen.isLikedByUser = true;
    }
    this.articleUpdated.emit(this.article);
  }

  likeArticle() {
    // Only allow the like if the article is not already liked
    if (!this.article.isLikedByUser) {
      this.isLikeButtonLoading = true;
      this.newsArticleService.newsArticleOpen.isLikeButtonLoading = true;
      this.newsArticleService.likeArticle(this.article.restId, (this.userService.userModel.isLoggedIn)?this.userService.userModel.id:null).subscribe( data => {
        let newsArticleLikes: Array<any> = [];
        // Check local storage for likes
        if (localStorage.getItem('newsArticleLikes') != null) {
          let localStorageLikes: Array<any> = JSON.parse(localStorage.getItem('newsArticleLikes'));
          newsArticleLikes = newsArticleLikes.concat(localStorageLikes);
        }
        // Check user object for likes
        if (this.userService.userModel.isLoggedIn)
          newsArticleLikes = newsArticleLikes.concat(this.userService.userModel.newsArticleLikes);
        // Add the new like data to the array
        newsArticleLikes.push(data);
        // Store data in local storage
        localStorage.setItem('newsArticleLikes', JSON.stringify(newsArticleLikes));
        // Store data on user model
        this.userService.userModel.newsArticleLikes = newsArticleLikes
        // Finally set article to liked
        this.article.isLikedByUser = true;
        this.article.likes++;
        ga('send', 'event', 'Click', 'Article Like', this.helperService.htmlDecode(this.article.title));
        this.isLikeButtonLoading = false;
        this.newsArticleService.newsArticleOpen.isLikeButtonLoading = false;
        this.articleUpdated.emit(this.article);
      }, error => {
        console.log(error);
        this.isLikeButtonLoading = false;
        this.newsArticleService.newsArticleOpen.isLikeButtonLoading = false;
      })
    }
  }

  removeLikeArticle() {
    if (this.article.isLikedByUser) {
      // We need the id for the like of the article
      let newsArticleLikeToRemove = {};
      if (localStorage.getItem('newsArticleLikes') != null) {
        let localStorageLikes: Array<any> = JSON.parse(localStorage.getItem('newsArticleLikes'));
        newsArticleLikeToRemove = localStorageLikes.find(newsArticleLikes => newsArticleLikes.newsArticleRestId == this.article.restId);
      }
      if (this.userService.userModel.isLoggedIn)
        newsArticleLikeToRemove = this.userService.userModel.newsArticleLikes.find(newsArticleLikes => newsArticleLikes.newsArticleRestId == this.article.restId);
      this.isLikeButtonLoading = true;
      this.newsArticleService.newsArticleOpen.isLikeButtonLoading = true;
      // Send to the server
      this.newsArticleService.removeLikeArticle(newsArticleLikeToRemove['newsArticleRestId'], newsArticleLikeToRemove['id']).subscribe( data => {
        let newsArticleLikes: Array<any> = [];
        // Check local storage for likes
        if (localStorage.getItem('newsArticleLikes') != null) {
          let localStorageLikes: Array<any> = JSON.parse(localStorage.getItem('newsArticleLikes'));
          newsArticleLikes = newsArticleLikes.concat(localStorageLikes);
        }
        // Check user object for likes
        if (this.userService.userModel.isLoggedIn)
          newsArticleLikes = newsArticleLikes.concat(this.userService.userModel.newsArticleLikes);
        // Remove like from array of likes
        newsArticleLikes = newsArticleLikes.filter(newsArticleLike => newsArticleLike.id != newsArticleLikeToRemove['id']);
        // Store data in local storage
        localStorage.setItem('newsArticleLikes', JSON.stringify(newsArticleLikes));
        // Store data on user model
        this.userService.userModel.newsArticleLikes = newsArticleLikes
        // Finally remove like form article
        this.article.isLikedByUser = false;
        this.article.likes--;
        ga('send', 'event', 'Click', 'Remove Article Like', this.helperService.htmlDecode(this.article.title));
        this.isLikeButtonLoading = false;
        this.newsArticleService.newsArticleOpen.isLikeButtonLoading = false;
        this.articleUpdated.emit(this.article);
      }, error => {
        console.log(error);
        this.isLikeButtonLoading = false;
        this.newsArticleService.newsArticleOpen.isLikeButtonLoading = false;
      })

    }
  }

  fixInsecureProtocol(content: string) {
    return content.toString().replace('http:', 'https:');
  }

}
