<div id="hpds" class="site-wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 hero-section">
        <div class="heading-contain">
          <!-- MOBILE LINKS -->
          <a href="/track-experience/faq" class="visible-sm visible-xs btn btn-outline-primary text-uppercase mobile-link">FAQ</a>
          <a href="/track-experience" class="visible-sm visible-xs btn btn-primary text-uppercase mobile-link">back to overview</a>
          <!-- DESKTOP LINKS -->
          <a href="/track-experience" class="hidden-sm hidden-xs btn btn-primary pull-right text-uppercase desktop-links">back to overview</a>
          <a href="/track-experience/faq" class="hidden-sm hidden-xs btn btn-outline-primary pull-right text-uppercase desktop-links" style="margin-right: 10px;">FAQ</a>
          <h1 class="heading1">
            THE OFFICIAL HIGH PERFORMANCE DRIVING SCHOOL OF DODGE//SRT<sup class="realign superscript">&reg;</sup>
          </h1>
          <div class="subhead">
            <img alt="Bondurant Racing School" class="img-responsive" src="/assets/images/srt/radford-racing.jpg">
            <p>All classes are held at Radford Racing School in Chandler, Arizona.
              If you have any questions, please contact the Dodge//SRT Concierge <br class="visible-sm">Team at
              800-998-1110 or <a href="mailto:srtte@dodgegarage.com">srtte@dodgegarage.com</a></p>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="main-content col-sm-12">
        <div class="row">
          <div class="col-md-6">
            <div class="tile">

              <p class="highlight">
                <strong class="text-primary">Once you schedule your class date with Radford,
                  <br class="visible-md">
                  it can't be rescheduled.</strong><br>
              </p>
              <p>
                Class dates and availability are subject to change until you have scheduled with Radford.
              </p>

              <div class="schedule-container">
                <div class="schedule-column list-schedule font-bold">
                  <div class="list-heading text-center">
                    SRT Experience <em>(U.S. only)</em>
                  </div>
                  <div class="dates">
                    <div class="date text-center">Sunday, February 16, 2025 @ 8 a.m.</div>
                    <div class="date text-center">Friday, March 7, 2025 @ 8 a.m.</div>
                    <div class="date text-center">Friday, April 11, 2025 @ 8 a.m.</div>
                    <div class="date text-center">Friday, May 2, 2025 @ 8 a.m.</div>
                    <div class="date text-center">Friday, June 13, 2025 @ 7 a.m.</div>
                    <div class="date text-center">Friday, July 25, 2025 @ 7 a.m.</div>
                    <div class="date text-center">Friday, August 22, 2025 @ 7 a.m.</div>
                  </div>
                </div>
                <div class="schedule-column list-schedule font-bold">
                  <div class="list-heading text-center">
                    2-Day Drag Racing   <em>(SRT Demon 170 only)</em>
                  </div>
                  <div class="dates">
                    <div class="date text-center">December 5-6, 2024 - SOLD OUT</div>
                    <div class="date text-center">December 19-20, 2024 - SOLD OUT</div>
                    <div class="date text-center">January 17-18, 2025 - SOLD OUT</div>
                    <div class="date text-center">February 6-7, 2025 - SOLD OUT</div>
                    <div class="date text-center">February 13-14, 2025 - SOLD OUT</div>
                    <div class="date text-center">March 6-7, 2025 - SOLD OUT</div>
                    <div class="date text-center">March 27-28, 2025 - SOLD OUT</div>
                    <div class="date text-center">April 10-11, 2025 - SOLD OUT</div>
                    <div class="date text-center">April 24-25, 2025 - SOLD OUT</div>
                    <div class="date text-center">May 1-2, 2025 - SOLD OUT</div>
                    <div class="date text-center">May 15-16, 2025 - SOLD OUT</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="tile registration-tile form-container">
              <iframe iframeAutoHeight title="SRT Registration Form" src="/srt-registration/register.action" width="100%" height="100%" style="border:0;overflow:hidden;height:100%;width:100%"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
