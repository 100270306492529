import { Component, OnInit, Input } from '@angular/core';
import { HPLAllocationService } from '../../services/trim/allocation/allocation.service';
import { AdobeTMService } from '../../../services/adobe-tm.service';
import { GtmService } from '../../../services/gtm-tracking.service';

// importing the trim data to use the build and price trim links
import { TRIMS } from '../../services/trim/trim-data';

@Component({
  selector: 'hpl-dealer-card',
  templateUrl: './dealer-card.component.html',
  styleUrls: ['./dealer-card.component.less']
})
export class HPLDealerCardComponent implements OnInit {
  @Input() model: string;
  
  @Input() dealerName: string
  @Input() dealercode: string;
  @Input() address: string;
  @Input() city: string;
  @Input() state: string;
  @Input() postalCode: string;
  @Input() phoneNumber: string;
  @Input() website: string;
  @Input() distance: number;
  @Input() isPowerBroker: boolean;
  @Input() quantity: number;

  @Input() trimLink: string;
  @Input() modelLink: string;
  @Input() trimType: string;
  @Input() trimLinkText: string;
  @Input() configId: number;
  @Input() currentFormData: object;

  hideSpecs: boolean = false;


  // format the websites that do not start with http so they work when clicked
  formattedWebsite(website : string){
    if (website.startsWith('http')){
      return website;
    } else {
      return "https://" + website;
    }
  }

  constructor(
    private adobeTMService: AdobeTMService,
    private gtmService: GtmService,
    private allocationService: HPLAllocationService
  ) {}

  ngOnInit(): void {
    if (this.model == 'Durango') {
      this.configId = 0

      if (
        this.currentFormData['trimName'] === null || 
        this.currentFormData['trimName'] === undefined || 
        this.currentFormData['trimName'].startsWith('WDES') || 
        this.currentFormData['trimName'].startsWith('WDEM')
      ) {
        this.hideSpecs = true;
      }
    }

    if (
      this.currentFormData['trimName'] === 'LB7S2925S' || 
      this.currentFormData['trimName'] === 'LB7M2925M'
    ) {
      this.hideSpecs = true;
    }
  }

  handleDownloadSpecsClicks(event) {
    if (this.model !== 'Durango' && this.configId === 0) {
      event.preventDefault();
    }else {
      console.log('yo');
      this.trackBuildAndPrice(this.model);
    }
  }

  trackBuildAndPrice(model: string): void {
    // let data: Object;
    let data = {
      "event":"interaction-click",
      "interaction": {
        "site":"dodgegarage",
        "type":"cta",
        "page":"shopping-tools:horsepower-locator",
        "location":"dealer-results",
        "description":"download-specs"
      }
    }

    // Logic for the Download Specs variant
    // if (model === 'Durango') {
    //   data = {
    //     "event":"interaction-click",
    //     "interaction": {
    //       "site":"dodgegarage",
    //       "type":"cta",
    //       "page":"shopping-tools:horsepower-locator",
    //       "location":"dealer-results",
    //       "description":"download-specs"
    //     }
    //   }
    // }else {
    //   data = {
    //     "event":"interaction-click",
    //     "interaction": {
    //       "site":"dodgegarage",
    //       "type":"cta",
    //       "page":"shopping-tools:horsepower-locator",
    //       "location":"dealer-results",
    //       "description":"build-and-price"
    //     },
    //     "vehicle": {
    //       "make": "dodge",
    //       "model": model.toLowerCase(),
    //       "year": "2024"
    //     }
    //   }
    // }

    let gtmData = {
      'event': 'hpl.dealer_card.download_specs',
      'hpl.dealer_card.download_specs.model': this.model
    }

    this.adobeTMService.pushToDataLayer(data);
    this.gtmService.pushEvent(gtmData);
  }
  
  trackCardLinks(type: string): void {
    let data: Object;

    if (type === 'website') {
      data = {
        "event":"interaction-click",
        "interaction": {
          "site":"dodgegarage",
          "type":"cta",
          "page":"shopping-tools:horsepower-locator",
          "location":"dealer-results",
          "description":"dealer-site"
        }
      }
    }

    if (type === 'phone') {
      data = {
        "event":"interaction-click",
        "interaction": {
          "site":"dodgegarage",
          "type":"nav",
          "page":"shopping-tools:horsepower-locator",
          "location":"dealer-results",
          "description":"click-to-call"
        }
      };
    }
    
    if (type === 'address') {
      data = {
        "event":"interaction-click",
        "interaction": {
        "site":"dodgegarage",
          "type":"nav",
          "page":"shopping-tools:horsepower-locator",
          "location":"dealer-results",
          "description":"view-address"
        }
      };
    }
    
    if (type === 'allocation') {
      data = {
        "event":"interaction-click",
        "interaction": {
          "site":"dodgegarage",
          "type":"tool",
          "page":"shopping-tools:horsepower-locator",
          "location":"dealer-results",
          "description":"open-orders-available-modal"
        }
      };
    }

    this.adobeTMService.pushToDataLayer(data);
  }

}
